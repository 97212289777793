body {  
  margin: 0;  
  font-family: 'Great Vibes';  
  background: grey;  
  color: white;  
  position: relative;  
  overflow: auto; /* Allow scrolling */  
}  
  
/* Pseudo-element for background image */  
body::before {  
  content: '';  
  position: fixed; /* Fixed to cover the entire viewport */  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  background: url('./assets/background.jpg') no-repeat center center;  
  background-size: cover;  
  opacity: 0.3; /* Adjust opacity as needed */  
  z-index: -10; /* Ensure background is behind content */  
  pointer-events: none; /* Ensure the background is non-interactive */  
}  
  
/* Responsive adjustments */  
@media (max-width: 768px) {  
  body::before {  
    background-size: contain; /* Adjust background size for smaller screens */  
  }  
}  
  
@media (max-width: 480px) {  
  body::before {  
    background-size: cover; /* Adjust background size for smallest screens */  
  }  
}  
  
a {  
  color: #ffffff;  
  text-decoration: none;  
  margin: 0 1rem;  
}  
  
a:hover {  
  text-decoration: underline;  
}  
  
button {  
  border: none;  
  background-color: transparent;  
  padding: 1rem 2rem;  
  cursor: pointer;  
  font-size: 1rem;  
  margin-bottom: 1rem;  
  appearance: button;  
  border: solid transparent;  
  border-width: 0 0 4px;  
  box-sizing: border-box;  
  color: #FFFFFF;  
  cursor: pointer;  
  display: inline-block;  
  font-family: 'Great Vibes';  
  font-size: 15px;  
  font-weight: 600;  
  letter-spacing: .7px;  
  line-height: 20px;  
  margin: 0;  
  outline: none;  
  overflow: visible;  
  padding: 13px 16px;  
  text-align: center;  
  text-transform: uppercase;  
  touch-action: manipulation;  
  transform: translateZ(0);  
  transition: filter .2s;  
  user-select: none;  
  -webkit-user-select: none;  
  vertical-align: middle;  
  white-space: nowrap;  
}  
  
button:main,  
button:focus {  
  user-select: auto;  
}  
  
button:hover:not(:disabled) {  
  filter: brightness(1.1);  
  -webkit-filter: brightness(1.1);  
}  
  
button:disabled {  
  cursor: auto;  
}  
  
button:active {  
  border-width: 4px 0 0;  
  background: none;  
}  
